import React from "react";
import {EmptyWidgetText} from "../../common/widgets/Widget";
import UnknownToken from "../../assets/images/icons/unknown_token.png";

import {Dropdown} from "./Dropdown";
import {useWalletHook} from "../../web3/walletHook";

import BigNumber from "bignumber.js";
import {Card, Flex} from "../../ui/components";

enum Modal {
  Switch,
  Connect,
  Confirm,
  Submitted,
}

export const AllPoolsWidget = ({manageLiquidity, ownedPairs, isLoading}) => {
  const {getTokenFromContract} = useWalletHook();

  const getWidget = () => {
    if (isLoading) {
      return [...new Array(6)].map(() => <Card size="l" loading height={90} />);
    }

    return (
      <>
        {ownedPairs.map((item, index) => {
          const assetAInfo = getTokenFromContract(item.token0.id);
          const assetBInfo = getTokenFromContract(item.token1.id);
          return (
            <Dropdown
              key={index}
              onManageClick={() => manageLiquidity(item.token0.id, item.token1.id, item.stable ? 1 : 2)}
              icon0={assetAInfo ? assetAInfo.logo : UnknownToken}
              icon1={assetBInfo ? assetBInfo.logo : UnknownToken}
              token0={assetAInfo && assetAInfo.symbol ? assetAInfo.symbol : item.token0.symbol}
              token1={assetBInfo && assetBInfo.symbol ? assetBInfo.symbol : item.token1.symbol}
              stable={item.stable}
              fee={new BigNumber(item.pairFee).div(100).toFixed(2)}
              reserveA={item.reserve0}
              reserveB={item.reserve1}
              apy={item.ampAPY ? item.apy + "-" + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2) : item.apy}
              tvl={item.reserveUSD}
              dark={true}
            />
          );
        })}
        {ownedPairs.length == 0 ? <EmptyWidgetText style={{textAlign: "center"}}></EmptyWidgetText> : <></>}
      </>
    );
  };

  return (
    <Flex column gap={2}>
      {getWidget()}
    </Flex>
  );
};

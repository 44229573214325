import {memo} from "react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import {AnalyticsOverview} from "./pages/overview";

import {AnalyticsProvider} from "./provider";

export const AnalyticsMain = memo(function AnalyticsMainComp(props: RouteComponentProps) {
  return (
    <AnalyticsProvider>
      <Switch>
        <Route exact path="/analytics">
          <AnalyticsOverview />
        </Route>
      </Switch>
    </AnalyticsProvider>
  );
});

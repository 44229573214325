import {AnalyticsGlobalData} from "./global";

const value = (_: number) => (isNaN(_) ? 0 : _);

export const transformTokens = ({ethPrice, ethDayPrice}: AnalyticsGlobalData = {} as any, tokens: any[] = []) =>
  tokens?.map?.(
    ({a, s, n, l, v, t, p, oneDayBefore = {}}: Record<string, string> & {oneDayBefore: Record<string, string>}) => ({
      address: a,
      symbol: s,
      name: n,
      liquidityUsd: value(+l * +p * ethPrice),
      price: value(+p * ethPrice),
      priceChange: value(+p * ethPrice - +oneDayBefore?.p * ethDayPrice),
      volumeUsd: value(+v),
      volumeDayUsd: value(+v - +oneDayBefore?.v),
      feesDayUsd: value(+v - +oneDayBefore?.v) * 0.005,
      txCount: value(+t),
      txDayCount: value(+t - +oneDayBefore?.t),
    }),
  );

import {memo} from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Flex,
  IconAsset,
  Loading,
  Text,
  Tooltip,
} from "../../../../ui/components";
import {numberWithCommas, formatPriceZeros} from "../../../../utils";
import {AnalyticsData} from "../../data";
import {useAnalytics} from "../../provider";

import * as SC from "./index.styles";

interface AnalyticsOverviewTokensProps {}

const ValueCell = memo(function ValueCellComp<T extends AnalyticsData["tokens"]["data"][number]>({
  data,
  prop,
  onlyNumber,
  onlyContent,
}: {
  data: T;
  prop: keyof T;
  onlyNumber?: boolean;
  onlyContent?: boolean;
}) {
  const d = onlyNumber ? 0 : 2;
  const s = onlyNumber ? null : "$ ";
  const content = (
    <Tooltip
      primary
      content={
        <>
          <SC.TooltipRow>
            <span>v2:</span>
            <span>
              {s}
              {numberWithCommas(data.v2?.[prop as string], d)}
            </span>
          </SC.TooltipRow>
          {data.v3 && (
            <SC.TooltipRow>
              <span>v3:</span>
              <span>
                {s}
                {numberWithCommas(data.v3?.[prop as string], d)}
              </span>
            </SC.TooltipRow>
          )}
        </>
      }
    >
      <div>
        {s}
        {numberWithCommas(data[prop as string], d)}
      </div>
    </Tooltip>
  );
  if (onlyContent) return content;
  return <SC.TableCol right>{content}</SC.TableCol>;
});

export const AnalyticsOverviewTokens = (props: AnalyticsOverviewTokensProps) => {
  const {
    data: {tokens},
  } = useAnalytics();

  return (
    <>
      <Box limit={476} noBreak>
        <CardContent extend={2}>
          <CardContent.Title title="Top Tokens">
            <SC.TitleColWrapper>
              <SC.TableCol right>
                Volume&nbsp;<strong>24h</strong>
              </SC.TableCol>
              <SC.TableCol right>Liquidity</SC.TableCol>
              <SC.TableCol right>
                Tx. Count&nbsp;<strong>24h</strong>
              </SC.TableCol>
              <SC.TableCol right>
                Fees&nbsp;<strong>24h</strong>
              </SC.TableCol>
              <SC.TableCol right>
                Price&nbsp;<strong>24h</strong>
              </SC.TableCol>
            </SC.TitleColWrapper>
          </CardContent.Title>
          <CardContent.Content>
            {!tokens.data?.length || tokens.isLoading ? (
              <>
                <Flex expandVertical justify="center">
                  <Loading size="xl" />
                </Flex>
              </>
            ) : (
              <>
                <Flex column gap={2}>
                  {tokens.data.map((token) => (
                    <Card key={token.address}>
                      <Flex justify="space-between">
                        <SC.TableCol>
                          <IconAsset address={token.address} button size="s" />
                          <Divider size={2} horizontal />
                          <SC.NameWrapper>
                            <Text h6 ellipsis>
                              {token.name}
                            </Text>
                            <Text bodyRegular color="gray400">
                              {token.symbol}
                            </Text>
                          </SC.NameWrapper>
                        </SC.TableCol>
                        <ValueCell data={token} prop="volumeDayUsd" />
                        <ValueCell data={token} prop="liquidityUsd" />
                        <ValueCell data={token} prop="txDayCount" onlyNumber />

                        <SC.TableCol right>
                          <ValueCell data={token} prop="feesDayUsd" onlyContent />
                          <Divider size={1} horizontal />
                          <Tooltip
                            align="center"
                            content={
                              <>
                                APY
                                <br />
                                Based on last 24h interest
                              </>
                            }
                          >
                            <Chip
                              value={((token.feesDayUsd / token.liquidityUsd) * 365 || 0).toFixed(2) + "%"}
                              valueColor={token.feesDayUsd ? true : undefined}
                            />
                          </Tooltip>
                        </SC.TableCol>
                        <SC.TableCol right>
                          <Chip
                            label={
                              <>
                                {"$ "}
                                {formatPriceZeros(token.price, 3)}
                              </>
                            }
                            value={((token.priceChange / token.price) * 100 || 0).toFixed(2) + "%"}
                            valueColor={token.priceChange}
                          />
                        </SC.TableCol>
                      </Flex>
                    </Card>
                  ))}
                </Flex>
              </>
            )}
          </CardContent.Content>
        </CardContent>
      </Box>
    </>
  );
};

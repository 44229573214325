import React, {useState} from "react";
import {L2AssetsListWidget} from "./L2AssetsListWidget";
import {WalletBannerWidget} from "./WalletBannerWidget";
import BigNumber from "bignumber.js";
import {WalletState} from "../../state";
import {useWalletSelector} from "../../state/hooks";
import {useWalletHook} from "../../web3/walletHook";
import {Box, LavaChip, lavaEffectHoverClassName, useLayoutMq} from "../../ui/components";
import {formatNumber} from "../../utils";
import {HistoryList} from "./TransactionsHistory/HistoryList";
import {Pool, Position} from "@uniswap/v3-sdk";
import {Percent} from "@uniswap/sdk-core";

type veLock = {
  amount: any;
};
export function Layer2WalletScreen() {
  const [totalSaved, setTotalSaved] = useState(0);
  const [l2AssetsValue, setl2AssetsValue] = useState(0);
  const tokens = useWalletSelector((state: WalletState) => state.tokens);
  const history = useWalletSelector((state: WalletState) => state.history);
  const ethPrice = useWalletSelector((state: WalletState) => state.ethPrice);
  const swapCostPerGas = useWalletSelector((state: WalletState) => state.swapCostPerGas);
  const gasFee = useWalletSelector((state: WalletState) => state.gasFee);
  const ownedLiq = useWalletSelector((state: WalletState) => state.ownedPairs);
  const ownedLiqV3 = useWalletSelector((state: WalletState) => state.ownedV3Liquidity);

  const ownedAmps = useWalletSelector((state: WalletState) => state.amplifiersv2);

  const daoInfo = useWalletSelector((state: WalletState) => state.daoInfo);

  const layoutBreak = useLayoutMq();

  const {getTokenFromContract, getKoiToken} = useWalletHook();

  React.useEffect(() => {
    var totalValue = new BigNumber(0);
    for (let i in tokens) {
      if (tokens[i].balance && tokens[i].price) {
        totalValue = totalValue.plus(new BigNumber(tokens[i].price).times(tokens[i].balance));
      }
    }

    for (let i in ownedLiq) {
      let _token = getTokenFromContract(ownedLiq[i].assetA);
      if (_token && _token.price && Number(ownedLiq[i].assetAAmount))
        totalValue = totalValue.plus(
          new BigNumber(ownedLiq[i].assetAAmount ? ownedLiq[i].assetAAmount : 0)
            .times(_token.price ? _token.price : 0)
            .times(2),
        );
    }

    for (let i in ownedAmps) {
      let stakes = ownedAmps[i].user_stakes;
      for (let j in stakes) {
        let _val = new BigNumber(ownedAmps[i].lpPrice).times(stakes[j].stakeAmount).toFixed();
        totalValue = totalValue.plus(_val);
      }
    }

    var koi_token = getKoiToken();
    for (let i in daoInfo.vekoi_locks) {
      if (koi_token && koi_token.price) {
        totalValue = totalValue.plus(new BigNumber(koi_token.price).times((daoInfo.vekoi_locks[i] as veLock).amount));
      }
    }

    for (let i in ownedLiqV3) {
      let _tokenA = ownedLiqV3[i].asset0;
      let _tokenB = ownedLiqV3[i].asset1;

      let _pool = ownedLiqV3[i].pool as Pool;
      if (_tokenA && _tokenA.price && _tokenB && _tokenB.price) {
        const position = new Position({
          pool: _pool,
          liquidity: Number(ownedLiqV3[i].liquidity.toString()),
          tickLower: Number(ownedLiqV3[i].tickLower.toString()),
          tickUpper: Number(ownedLiqV3[i].tickUpper.toString()),
        });

        const liqAmounts = position.burnAmountsWithSlippage(new Percent("0"));

        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount0.toString())
            .div(Math.pow(10, _tokenA.decimals))
            .times(_tokenA.price ? _tokenA.price : 0),
        );

        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount1.toString())
            .div(Math.pow(10, _tokenB.decimals))
            .times(_tokenB.price ? _tokenB.price : 0),
        );
      }
    }

    let currentSwapCost = new BigNumber(ethPrice).times(swapCostPerGas).times(gasFee);
    setTotalSaved(new BigNumber(history.length).times(currentSwapCost).toNumber());
    setl2AssetsValue(totalValue.toNumber());

    return () => {};
  }, [tokens, history, ownedLiq, daoInfo, ownedLiqV3]);

  return (
    <>
      <Box vertical>
        <Box className={lavaEffectHoverClassName}>
          <LavaChip heading="L2 Assets Value" value={`$${formatNumber(l2AssetsValue, 2)}`} leader={layoutBreak} />
          <LavaChip
            heading="Saved on gas vs L1"
            value={`$${formatNumber(totalSaved, 2)}`}
            palette="purple"
            follower={layoutBreak}
          />
        </Box>
        <Box limit={430}>
          <L2AssetsListWidget assets={tokens} liq={ownedLiq} liqv3={ownedLiqV3} daoInfo={daoInfo} amps={ownedAmps} />
          <HistoryList history={history} />
        </Box>
        <WalletBannerWidget />
      </Box>
    </>
  );
}

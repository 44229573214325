import {useState} from "react";
import {useHistory} from "react-router-dom";
import {LiquidityRewardsWidget} from "./LiquidityRewardsWidget";
import {YourLiquidityWidget} from "./YourLiquidityWidget";
import {useWalletSelector} from "../../state/hooks";
import {Box, Tabs, Scroll, Title, Divider} from "../../ui/components";
import {selectFarms} from "../../state/selectors";

export const AmplifierScreen = () => {
  let history = useHistory();

  const [section, setSection] = useState("a");

  const activePairs = useWalletSelector(selectFarms);

  const manageLiquidity = (poolId, v2) => {
    if (v2) history.push(`/farmv2/${poolId}`);
    else history.push(`/farm/${poolId}`);
  };

  return (
    <Box vertical onlyContain>
      <Title title="Farms" />

      <Tabs
        onSelect={(val) => setSection(val)}
        standalone
        full
        tabs={[
          {value: "a", label: "All Farms"},
          {value: "b", label: "My Farms"},
        ]}
      />

      <Divider size={3} />

      <Scroll padding="0 0 3" parentPadding="2" responsive height={322 * 2 + 16 * 1}>
        {section == "a" ? (
          <YourLiquidityWidget
            title={"Active Farms"}
            ownedPairs={activePairs.filter((_) => _.payoutToken?.symbol == "ZK" || _.payoutToken?.symbol == "NODL")}
            manageLiquidity={manageLiquidity}
            isLoading={!activePairs.length}
          />
        ) : (
          <YourLiquidityWidget
            title={"My Farms"}
            ownedPairs={activePairs.filter((_) => _.isUserFarm)}
            manageLiquidity={manageLiquidity}
            isLoading={!activePairs.length}
          />
        )}
      </Scroll>

      <LiquidityRewardsWidget isVisible={false} onClose={() => {}} />
    </Box>
  );
};

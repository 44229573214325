import gql from 'graphql-tag'

export const POOL_TICKS = gql`
  query ticks($pool: Bytes!, $skip: Int!) {
    ticks(where: {poolAddress: $pool, liquidityNet_not: "0"} first: 1000, orderBy: tickIdx orderDirection: asc, skip: $skip) {
        tickIdx
        liquidityGross
        liquidityNet
      }
  }
`

export const GLOBAL_POOLS_V3 = gql`
  query pooldata($token0: ID!, $token1: ID!) {
    pools (first: 200, orderBy: totalValueLockedETH, orderDirection: desc) {
      id
      feeTier
      totalValueLockedETH
      totalValueLockedUSD
      token0{
        id
        symbol
        decimals
      }
      token1{
        id
        symbol
        decimals
      }
    }
    token0:token(id: $token0){
      id
      symbol
      name
      decimals
      derivedETH
      volumeUSD
    }
    token1:token(id: $token1){
      id
      symbol
      name
      decimals
      derivedETH
      volumeUSD
    }
    bundle(id: 1){
      ethPriceUSD
    }
  }
`
import {BaseContractMethod, ContractTransactionResponse} from "ethers";
import {Signer} from "zksync-ethers";

const API_URL = "https://api.koi.finance";

export const isSponsoredPaymaster = async () => {
  try {
    const response = await fetch(API_URL + "/api/sponsored-paymaster").then((_) => _.json());
    return response.enabled;
  } catch {
    return false;
  }
};

export const sendSponsoredPaymaster = async (
  method: BaseContractMethod,
  signer: Signer,
  params: any[] = [],
  extraParams: any = {},
) => {
  let txData: any;

  const sponsoredPromise = new Promise<boolean>(async (resolve) => {
    try {
      const preview = await method.populateTransaction(...params, {
        ...extraParams,
      });

      const payload = {
        from: preview.from || signer.address || (method as any)?._contract?.runner?.address,
        to: preview.to,
        data: preview.data,
        gasLimit: String(extraParams.gasLimit),
      };

      const response = await fetch(API_URL + "/api/sponsored-paymaster", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`[Sponsored Paymaster] Response error: ${response.status}`);
      }

      const data = await response.json();

      txData = data;
      resolve(true);
    } catch (error) {
      console.error("[Sponsored Paymaster] Error:", error);
      resolve(false);
    }
  });

  const txPromise = new Promise<ContractTransactionResponse>(async (resolve) => {
    await sponsoredPromise;
    if (txData) {
      resolve(
        await method(...params, {
          ...extraParams,
          customData: txData.customData,
          maxFeePerGas: txData.maxFeePerGas,
          gasLimit: txData.gasLimit,
        }),
      );
    } else {
      resolve(
        await method(...params, {
          ...extraParams,
        }),
      );
    }
  });

  return {
    isSponsored: sponsoredPromise,
    tx: txPromise,
  };
};

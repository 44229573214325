import React from "react";
import styled from "styled-components";
import bannerImage from "../../assets/images/lp/dao_card.png";
import {CardContent, Text, Icon, layoutMq} from "../../ui/components";

export const DaoBannerWidget = () => {
  return (
    <View>
      <BannerTextWrapper>
        <Text h2>
          What is veKOI <br /> (Vote Escrow Koi)?
        </Text>
        <BannerTextContent bodyRegular color="gray400" style={{marginTop: "20px", marginBottom: "10px"}}>
          A veKOI NFT is the DAO token for the koi.finance ecosystem. To receive veKOI, a user can lock their KOI tokens
          for a period of time (7-728 days), and in return receives a veKOI NFT. A veKOI NFT represents a vote weight in
          the DAO based on the amount of KOI locked and timeframe it was locked for.
        </BannerTextContent>
        <BannerTextWrapperTwo>
          <BannerTextContent bodyRegular color="black">
            <Icon icon="checkCircle" color="overgrown" size="s" />
            &nbsp; Boost your farming rewards by up to 3x.
          </BannerTextContent>
          <BannerTextContent bodyRegular color="black">
            <Icon icon="checkCircle" color="overgrown" size="s" />
            &nbsp; Vote to direct KOI towards farming pools and bond offerings.
          </BannerTextContent>
          <BannerTextContent bodyRegular color="black">
            <Icon icon="checkCircle" color="overgrown" size="s" />
            &nbsp; Earn platform revenue rewards and more.
          </BannerTextContent>
        </BannerTextWrapperTwo>
      </BannerTextWrapper>
    </View>
  );
};

const View = styled(CardContent)`
  grid-area: banner;
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 280px;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  background: white;
  background-image: url(${bannerImage});

  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;

  ${layoutMq(`
    background-image: none;
  `)}
`;

const BannerImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  z-index: 0;

  @media (max-width: 600px) {
    width: auto;
    height: 100%;
  }
`;
const BannerCheck = styled.img`
  width: 20px;
  height: 20px;
  margin: 5px;
  margin-left: 0;
`;

const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  margin-right: auto;
  z-index: 2;

  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const BannerTextContent = styled(Text)`
  align-items: center;
  display: flex;
  align-items: center;
`;

const BannerTextWrapperTwo = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 10px;
  justify-content: space-around;
`;

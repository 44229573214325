import {memo, PropsWithChildren, ReactNode} from "react";
import {useHistory} from "react-router-dom";
import {layoutMqValue} from "../box";
import {Chip} from "../chip";

import {Icon} from "../icon";
import {Skeleton} from "../skeleton";

import * as SC from "./index.styles";

interface TitleProps {
  title?: ReactNode;
  chip?: string;
  loading?: boolean;
  goBackLink?: string;
  breakpoint?: number | string;
  extend?: boolean;
  breakContent?: boolean;
}

export const TitleDash = SC.Dash;

export const Title = memo(function TitleComponent(
  props: PropsWithChildren<TitleProps> & Pick<JSX.IntrinsicElements["div"], "style" | "className">,
) {
  const {title, chip, goBackLink, loading, breakpoint = layoutMqValue, children, ...rest} = props;

  const history = useHistory();

  return (
    <SC.Wrapper {...rest} bp={+breakpoint} hasArrow={!!goBackLink}>
      <SC.TitleWrapper>
        {goBackLink && (
          <SC.Icon>
            <Icon onClick={() => history.push(goBackLink)} icon="arrow" rotate={1} size="m" button color="c300" />
          </SC.Icon>
        )}
        <Skeleton variant="contrast" active={loading}>
          <SC.Title>{title}</SC.Title>
        </Skeleton>
        <SC.TitleMargin />
        {chip && (
          <Skeleton variant="contrast" active={loading}>
            <Chip variant="white" label={chip} />
          </Skeleton>
        )}
      </SC.TitleWrapper>
      {children && <SC.Content>{children}</SC.Content>}
    </SC.Wrapper>
  );
});

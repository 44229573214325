import {useMemo, useState} from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import {formatNumber, formatPriceZeros, numberWithCommas} from "../../utils/formatNumber";
import {useWalletHook} from "../../web3/walletHook";
import {useAccount} from "wagmi";

import {
  Card,
  Modal,
  CardContent,
  Flex,
  Icon,
  IconAsset,
  Text,
  Button,
  Divider,
  Empty,
  Tabs,
} from "../../ui/components";
import {Address, Asset} from "../../web3/types";
import {Pool, Position} from "@uniswap/v3-sdk";
import {Percent} from "@uniswap/sdk-core";
import {useLocalStorageSettings} from "../../hooks/useLocalStorage";

interface L2AssetsListWidgetProps {
  assets: Record<Address, Asset>;
  liq: any[];
  liqv3: any[];
  daoInfo?: any;
  amps: any[];
}
export const L2AssetsListWidget = ({assets, liq, daoInfo, liqv3, amps}: L2AssetsListWidgetProps) => {
  const {addTokenToWallet, getTokenFromContract, getKoiToken} = useWalletHook();
  const {isConnected, address} = useAccount();

  const [hideZero, setHideZero] = useLocalStorageSettings<true | 1 | 0>("wallet-values-filter", 0);
  const [showTokenInfo, setShowTokenInfo] = useState(false);
  const [selectedToken, setSelectedToken] = useState<any>(null);

  const list = useMemo(() => {
    var allVals: Record<Address, Asset> = JSON.parse(JSON.stringify(assets));

    for (let i in liq) {
      let _tokenA = getTokenFromContract(liq[i].assetA);
      let _tokenB = getTokenFromContract(liq[i].assetB);

      let assetAPrice = _tokenA && _tokenA.price ? _tokenA.price : "0";
      let assetBPrice = _tokenB && _tokenB.price ? _tokenB.price : "0";

      allVals["lp_" + String(i)] = {
        ...liq[i],
        assetAPrice,
        assetBPrice,
        assetA: _tokenA,
        assetB: _tokenB,
      };
    }

    var koi_token = getKoiToken();
    for (let i in daoInfo.vekoi_locks) {
      if (koi_token && koi_token.price) {
        allVals["ve_" + String(i)] = {
          ...koi_token,
          balance: daoInfo.vekoi_locks[i].amount,
          address: daoInfo.vekoi_address,
          name: "Vote Escrow Koi",
          weight: daoInfo.vekoi_locks[i].vote_weight,
          id: daoInfo.vekoi_locks[i].index,
        };
      }
    }

    for (let i in amps) {
      let stakes = amps[i].user_stakes;
      let token0 = amps[i].token0;
      let token1 = amps[i].token1;

      for (let j in stakes) {
        let _val = new BigNumber(amps[i].lpPrice).times(stakes[j].stakeAmount).toFixed();
        allVals["ampv2_" + String(i)] = {
          ...token0,
          value: _val,
          balance: stakes[j].stakeAmount,
          assetA: token0,
          assetB: token1,
          hasAmp: true,
        };
      }
    }

    for (let i in liqv3) {
      let _tokenA = liqv3[i].asset0;
      let _tokenB = liqv3[i].asset1;

      let _pool = liqv3[i].pool as Pool;
      if (_tokenA && _tokenA.price && _tokenB && _tokenB.price) {
        const position = new Position({
          pool: _pool,
          liquidity: Number(liqv3[i].liquidity.toString()),
          tickLower: Number(liqv3[i].tickLower.toString()),
          tickUpper: Number(liqv3[i].tickUpper.toString()),
        });

        const liqAmounts = position.burnAmountsWithSlippage(new Percent("0"));
        let totalValue = new BigNumber(0);
        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount0.toString())
            .div(Math.pow(10, _tokenA.decimals))
            .times(_tokenA.price ? _tokenA.price : 0),
        );

        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount1.toString())
            .div(Math.pow(10, _tokenB.decimals))
            .times(_tokenB.price ? _tokenB.price : 0),
        );

        allVals["lpv3_" + String(i)] = {
          ...liqv3[i],
          assetAPrice: _tokenA.price,
          assetBPrice: _tokenB.price,
          assetA: _tokenA,
          assetB: _tokenB,
          hasLiqV3: true,
          symbol: "V3 POS",
          v3Price: totalValue.toFixed(),
          balance: 1,
        };
      }
    }

    return Object.values(allVals)
      .map((asset) => {
        const a: any = asset;
        let usdAmount: number;
        if (a.hasLiq == true) {
          usdAmount = new BigNumber(a.assetAAmount)
            .times(a.assetAPrice)
            .plus(new BigNumber(a.assetBAmount).times(a.assetBPrice))
            .toNumber();
        } else if (a.hasLiqV3) {
          usdAmount = new BigNumber(a.v3Price).toNumber();
        } else if (a.hasAmp) {
          usdAmount = new BigNumber(a.value.replace(/,/g, "")).toNumber();
        } else {
          usdAmount = new BigNumber(a.balance).times(a.price).toNumber();
        }
        return {
          id: undefined as string | undefined,
          ...asset,
          amount: +asset.price * +asset.balance,
          usdAmount,
        };
      })
      .filter((asset) => {
        if (hideZero === true) return true;
        return hideZero ? asset.usdAmount >= 1 : +asset.balance;
      })
      .sort((a: any, b: any) => b.usdAmount - a.usdAmount);
  }, [assets, liq, hideZero, daoInfo, liqv3]);

  const getAssetName = () => {
    if (selectedToken) {
      if (selectedToken.hasLiq || selectedToken.hasLiqV3) {
        return selectedToken.symbol;
      }

      var token = getTokenFromContract(selectedToken.address);

      return token.name;
    }
  };

  const getAssetSymbol = (_asset) => {
    if (_asset) {
      if (_asset.hasLiq || _asset.hasLiqV3) {
        return _asset.symbol;
      }

      var token = getTokenFromContract(_asset.address);

      if (token) return token.symbol;
    }

    return "";
  };

  const getAssetPriceHoldings = () => {
    if (selectedToken) {
      if (selectedToken.hasLiq) {
        return new BigNumber(selectedToken.assetAAmount).times(selectedToken.assetAPrice).times(2);
      }

      if (selectedToken.hasLiqV3) {
        return new BigNumber(selectedToken.v3Price);
      }

      return new BigNumber(selectedToken.price).times(selectedToken.balance).toFixed(2);
    }

    return 0;
  };

  const getAssetIcons = () => {
    if (selectedToken) {
      if (selectedToken.hasLiq || selectedToken.hasLiqV3) {
        return (
          <Flex gap={3}>
            <IconAsset asset={selectedToken.assetA} button size="s" />
            <LPAsset asset={selectedToken.assetB} button size="s" />
          </Flex>
        );
      }

      return <IconAsset contrast asset={selectedToken} size="m" />;
    }

    return 0;
  };

  return (
    <>
      <CardContent extend>
        <CardContent.Title title="Your L2 assets">
          <Flex gap={2} align="center">
            <Text bodyRegular color="gray400">
              Show
            </Text>
            <Tabs
              selected={hideZero}
              onSelect={(_) => setHideZero(_)}
              micro
              tabs={[
                {value: true, label: "All"},
                {value: 0, label: ">$0"},
                {value: 1, label: ">$1"},
              ]}
            />
          </Flex>
        </CardContent.Title>
        <CardContent.Content>
          {!list.length ? (
            <Empty title="No assets yet" />
          ) : (
            <Flex gap={1.5} column>
              {list.map((asset) => {
                if (asset.hasLiq) {
                  return (
                    <Card key={[asset.address, asset.assetA, asset.assetB].join()}>
                      <Flex gap={3}>
                        <Flex>
                          <IconAsset stack asset={asset.assetA} button size="s" />
                          <IconAsset stack asset={asset.assetB} button size="s" />
                        </Flex>
                        <div>
                          {(() => {
                            const [, token, pair] = asset.symbol.match(/^(.+) \((.+)\)$/) || [];
                            return (
                              <>
                                <Text bodyRegular color="gray400">
                                  {token}
                                </Text>
                                <Text h6>{pair}</Text>
                              </>
                            );
                          })()}
                        </div>
                        <Flex.Grow />
                        <Text align="right">
                          <Text bodyMedium>{formatPriceZeros(asset.balance, 4, 8)}</Text>
                          <Text bodyRegular color="gray400">
                            $
                            {numberWithCommas(
                              new BigNumber(asset.assetAAmount).times(asset.assetAPrice).times(2).toFixed(),
                              2,
                            )}
                          </Text>
                        </Text>
                        <Icon
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setSelectedToken(asset);
                            setShowTokenInfo(true);
                          }}
                          icon="arrow"
                          button
                          size="s"
                          rotate={3}
                          color="c300"
                        />
                      </Flex>
                    </Card>
                  );
                }
                if (asset.weight) {
                  return (
                    <Card key={[asset.address, asset.id].join()}>
                      <Flex gap={3}>
                        <Flex>
                          <IconAsset asset={asset as any} button size="s" />
                        </Flex>
                        <div>
                          <>
                            <Text bodyRegular color="gray400">
                              {"Vote Escrow KOI"}
                            </Text>
                            <Text h6>
                              veKOI
                              <Divider horizontal size={2} />
                              <Text h7 inline color="gray400">
                                NFT #{asset.id}
                              </Text>
                            </Text>
                          </>
                        </div>
                        <Flex.Grow />
                        <Text align="right">
                          <Text bodyMedium>{formatPriceZeros(asset.balance, 6, 8)}</Text>
                          <Text bodyRegular color="gray400">
                            ${numberWithCommas(new BigNumber(asset.balance).times(asset.price).toFixed(), 2)}
                          </Text>
                        </Text>
                        <Icon
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setSelectedToken(asset);
                            setShowTokenInfo(true);
                          }}
                          icon="arrow"
                          button
                          size="s"
                          rotate={3}
                          color="c300"
                        />
                      </Flex>
                    </Card>
                  );
                }
                if (asset["hasLiqV3"]) {
                  return (
                    <Card key={["v3", asset.id, asset.address, asset.assetA, asset.assetB].join()}>
                      <Flex gap={3}>
                        <Flex>
                          <IconAsset stack asset={asset.assetA} button size="s" />
                          <IconAsset stack asset={asset.assetB} button size="s" />
                        </Flex>
                        <div>
                          <>
                            <Text bodyRegular color="gray400">
                              {"V3 Position"}
                            </Text>
                            <Text h6>{`${asset.assetA.symbol}/${asset.assetB.symbol}`}</Text>
                          </>
                        </div>
                        <Flex.Grow />
                        <Text align="right">
                          <Text bodyMedium>NFT #{asset.id}</Text>
                          <Text bodyRegular color="gray400">
                            ${numberWithCommas((asset as any).v3Price, 2)}
                          </Text>
                        </Text>
                        <Icon
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setSelectedToken(asset);
                            setShowTokenInfo(true);
                          }}
                          icon="arrow"
                          button
                          size="s"
                          rotate={3}
                          color="c300"
                        />
                      </Flex>
                    </Card>
                  );
                }

                if (asset["hasAmp"]) {
                  return (
                    <Card key={["farm", asset.id, asset.address, asset.assetA, asset.assetB].join()}>
                      <Flex gap={3}>
                        <Flex>
                          <IconAsset stack asset={asset.assetA} button size="s" />
                          <IconAsset stack asset={asset.assetB} button size="s" />
                        </Flex>
                        <div>
                          <>
                            <Text bodyRegular color="gray400">
                              Farm Deposit
                            </Text>
                            <Text h6>
                              {asset.assetA.symbol}/{asset.assetB.symbol}
                            </Text>
                          </>
                        </div>
                        <Flex.Grow />
                        <Text align="right">
                          <Text bodyMedium>{formatNumber(asset.balance, 2)}</Text>
                          <Text bodyRegular color="gray400">
                            ${formatNumber(asset.value, 2)}
                          </Text>
                        </Text>
                        <Icon
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setSelectedToken(asset.assetA);
                            setShowTokenInfo(true);
                          }}
                          icon="arrow"
                          button
                          size="s"
                          rotate={3}
                          color="c300"
                        />
                      </Flex>
                    </Card>
                  );
                }

                return (
                  <Card key={asset.address}>
                    <Flex gap={3}>
                      <IconAsset asset={asset as any} button size="s" />
                      <Text h6>{getAssetSymbol(asset)}</Text>
                      <Flex.Grow />
                      <Text align="right">
                        <Text bodyMedium>{formatPriceZeros(asset.balance, 6, 8)}</Text>
                        <Text bodyRegular color="gray400">
                          ${numberWithCommas(asset.amount, 2)}
                        </Text>
                      </Text>
                      <Icon
                        style={{cursor: "pointer"}}
                        onClick={() => {
                          setSelectedToken(asset);
                          setShowTokenInfo(true);
                        }}
                        icon="arrow"
                        button
                        size="s"
                        rotate={3}
                        color="c300"
                      />
                    </Flex>
                  </Card>
                );
              })}
            </Flex>
          )}
        </CardContent.Content>
      </CardContent>

      <Modal
        title={getAssetName() + " Balance"}
        isVisible={showTokenInfo}
        onClose={() => {
          setShowTokenInfo(false);
        }}
      >
        <Flex align="center" column>
          {getAssetIcons()}
          <Divider size={5} />
          <Text h4>
            {formatNumber(selectedToken ? selectedToken.balance : 0, 2)}{" "}
            {getAssetSymbol(selectedToken ? selectedToken : null)}
          </Text>
          <Divider size={2} />
          <Text bodyRegular>${formatNumber(getAssetPriceHoldings(), 2)}</Text>
        </Flex>
        <StatRow>
          <StatText bodyRegular color="gray400"></StatText>
          <StatTextHighlight bodyMedium></StatTextHighlight>
        </StatRow>
        <Divider size={4} />

        <Button full link={`https://era.zksync.network/token/${selectedToken?.address}?a=${address}`} newTab>
          See on Explorer
        </Button>
      </Modal>
    </>
  );
};

interface ListProps {
  isExpanded: boolean;
}

export const List = styled.ul<ListProps>`
  overflow-y: auto;
  height: 100%;
  padding-right: 19px;
`;

export const AssetValues = styled.div`
  text-align: right;
`;

const LPAsset = styled(IconAsset)`
  margin-left: -18px !important;
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlightLink = styled.a`
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :hover {
    color: var(--color-overgrown);
  }
`;

const StatTextHighlight = styled(Text)`
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

/* eslint react-hooks/rules-of-hooks: 0 */
import {createContext, useContext, type PropsWithChildren} from "react";
import {useAnalyticsData} from "../data";

const analyticsContext = () => {
  const data = useAnalyticsData();

  return {
    ready: true,
    data,
  };
};

type AnalyticsContextProps = ReturnType<typeof analyticsContext>;
const AnalyticsContext = createContext<AnalyticsContextProps | null>(null);
export const AnalyticsProvider = ({children}: PropsWithChildren<{}>) => {
  const analytics = analyticsContext();
  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (context === null) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }

  return context;
};

import {useMemo, useState} from "react";

import {Flex, Divider, Skeleton, Loading} from "../../../../../../ui/components";
import {abbrNum} from "../../../../../../utils";
import {AnalyticsData} from "../../../../data";
import {useAnalytics} from "../../../../provider";
import {AnalyticsChartLiquidityChart} from "./chart";

import * as SC from "./index.styles";

interface AnalyticsChartLiquidityProps {}

const {format: formatDayLong} = new Intl.DateTimeFormat("en-us", {month: "short", day: "numeric", year: "numeric"});

export type Point = AnalyticsData["chart"]["data"][number];

export const AnalyticsChartLiquidity = (props: AnalyticsChartLiquidityProps) => {
  const {
    data: {chart},
  } = useAnalytics();
  const [hover, setHover] = useState<number>();

  const {data, min, max} = useMemo(() => {
    let values = chart.data.slice(6);

    let {min, max} = values.reduce(
      (acc, _) => ({
        max: Math.max(acc.max, _.liquidity),
        min: Math.min(acc.min, _.v2.liquidity),
      }),
      {max: -Infinity, min: Infinity},
    );

    min = Math.max(0, min - (max - min) * 0.2);
    const gap = max - min;

    const simple = values.map((_) => {
      const value = 1 - (max - _.liquidity) / gap;
      // const inner = (1 - ((max - _.v2.liquidity) / gap)) * 0.8;
      const inner = 1 - (max - _.v2.liquidity) / gap;
      return {
        date: _.date,
        value,
        inner: inner,
        l: _.liquidity,
        v2: _.v2?.liquidity,
        v3: _.v3?.liquidity,
      };
    });

    return {data: simple, min, max};
  }, [chart.data]);

  const {xAxis, yAxis} = useMemo(() => {
    const nX = 5;
    const nY = 3;
    const x = Array.from({length: nX})
      .map((_, i) => i / (nX - 1))
      .map((_) => data[Math.round((data.length - 1) * _)]?.date)
      .map(formatDayLong);
    const y = Array.from({length: nY})
      .map((_, i) => i / (nY - 1))
      .map((_) => min + (max - min) * _)
      .map((_) => abbrNum(_, 3));
    return {
      xAxis: x,
      yAxis: y,
    };
  }, [data, min, max]);

  const agg = useMemo(() => {
    return data.reduce(
      (acc, _) => ({
        l: acc.l + _.l,
        v2: acc.v2 + _.v2,
        v3: acc.v3 + (_.v3 || 0),
      }),
      {l: 0, v2: 0, v3: 0},
    );
  }, [data]);

  const isHover = hover !== undefined;
  const isLoading = chart.isLoading || !data.length;

  return (
    <>
      <SC.Wrapper>
        {isLoading && <Loading size="xl" />}
        <Flex justify="space-between" align="flex-start">
          <div>
            <SC.Title>KOI Liquidity</SC.Title>
            <SC.TitleValue>
              <Skeleton hideChildren active={isLoading} width={140}>
                <span>${abbrNum(!isHover ? agg.l : data[hover!]?.l, 5)}</span>
              </Skeleton>
            </SC.TitleValue>
            <Divider size={0.5} />
            <SC.TitleValueSubtitle>
              <Skeleton hideChildren active={isLoading} width={100}>
                <span>{isHover ? formatDayLong(data[hover!]?.date) : "\u00A0"}</span>
              </Skeleton>
            </SC.TitleValueSubtitle>
          </div>
          <div>
            <Skeleton hideChildren active={isLoading}>
              <SC.LegendRow>
                <SC.LegendColor />
                v2
                <span>${abbrNum(!isHover ? agg.v2 : data[hover!]?.v2)}</span>
              </SC.LegendRow>
            </Skeleton>
            <Divider size={1} />
            <Skeleton hideChildren active={isLoading}>
              <SC.LegendRow>
                <SC.LegendColor alt />
                v3
                <span>${abbrNum(!isHover ? agg.v3 : data[hover!]?.v3 || 0)}</span>
              </SC.LegendRow>
            </Skeleton>
          </div>
        </Flex>
        <SC.Chart onMouseLeave={() => setHover(undefined)}>
          {!isLoading && (
            <>
              <AnalyticsChartLiquidityChart {...{data, max, min, setHover}} />
              <SC.XAxis>
                {xAxis.map((_) => (
                  <span>{_}</span>
                ))}
              </SC.XAxis>
              <SC.YAxis>
                {yAxis.map((_) => (
                  <span>{_}</span>
                ))}
              </SC.YAxis>
            </>
          )}
        </SC.Chart>
      </SC.Wrapper>
    </>
  );
};

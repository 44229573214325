import styled from "styled-components";
import {useMediaQuery} from "../../../hooks/useMediaQuery";

import {mq, size} from "../../styles";

interface BoxProps {
  vertical?: boolean;
  column?: boolean;
  limit?: number;
  minLimit?: boolean;
  noBreak?: boolean;
  onlyContain?: boolean;
  relative?: boolean;
  half?: boolean;
  extendOnMobile?: boolean;
}

export const layoutMqValue = 1080;
export const layoutMq = mq(layoutMqValue);
export const useLayoutMq = () => useMediaQuery(layoutMqValue);

const MainBox = styled.div<BoxProps>`
  ${(_) => !_.onlyContain && size.gap(3)}

  display: flex;
  align-items: stretch;
  max-width: 1080px;
  width: 100%;

  ${(_) =>
    _.half &&
    `
    max-width: 520px;
  `}

  ${(_) =>
    (_.vertical || _.column) &&
    `
    flex-direction: column;
  `}

  ${(_) =>
    _.limit &&
    `
    min-height: ${_.limit}px;
    max-height: ${_.limit}px;
  `}

  ${(_) =>
    _.minLimit &&
    `
    min-height: min-content;
    max-height: min-content;
  `}


  ${(_) =>
    !_.column &&
    `
    & > * {
      flex-grow: 1;
      flex-basis: 0;
    }
  `}

  ${(_) =>
    !_.noBreak &&
    layoutMq(`
    flex-direction: column;
    max-width: 520px;
    min-height: initial;
    max-height: initial;

    & > * {
      flex-grow: initial;
      flex-basis: initial;

    }
  `)}

  ${(_) =>
    _.extendOnMobile &&
    layoutMq(`
    max-width: 100%;
  `)}

  ${(_) =>
    _.relative &&
    `
    position: relative;
  `}
`;

const Fit = styled(MainBox)`
  display: flex;
  align-items: initial;
  max-width: initial;
  position: absolute;
  width: 100%;
  height: 100%;

  ${(_) => !_.onlyContain && size.gap(3)}

  ${layoutMq(`
    display: contents;
  `)}
`;

export const Box = Object.assign(MainBox, {Fit});

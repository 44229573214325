import {memo, PropsWithChildren} from "react";

import {withColor} from "../-with-color";

import * as SC from "./index.styles";

export interface CardContentProps {
  disabled?: boolean;
  extend?: boolean | 1 | 2;
  forceContract?: boolean;
  mobileMaxHeight?: number;
}
const CardContentComp = memo(
  withColor(function CardContentComponent(props: PropsWithChildren<CardContentProps>) {
    return <SC.Wrapper {...props} />;
  }),
);

interface CardContentTitleProps {
  title: string;
  center?: boolean;
}
const CardContentTitle = memo(function CardContentTitleComponent(props: PropsWithChildren<CardContentTitleProps>) {
  const {title, children} = props;
  return (
    <SC.TitleWrapper {...props}>
      <SC.Title>{title}</SC.Title>
      <SC.TitleContent>{children}</SC.TitleContent>
    </SC.TitleWrapper>
  );
});

export interface CardContentContentProps {}
const CardContentContent = memo(function CardContentContentComponent(
  props: PropsWithChildren<CardContentContentProps>,
) {
  const {children} = props;
  return <SC.ContentWrapper>{children}</SC.ContentWrapper>;
});

export const CardContent = Object.assign(CardContentComp, {
  Title: CardContentTitle,
  Content: CardContentContent,
});

import {useQuery} from "@tanstack/react-query";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";

export const useGetPairsV2Data = () => {
  const query = useQuery({
    queryKey: ["pairs-v2"],
    queryFn: async () => {
      const url = getGraphQLUrl();
      return await fetchGraphQL(
        url,
        `
        query {
          pairs (first: 15, orderBy: volumeUSD, orderDirection: desc) {
            a: token0 {
              s: symbol
            }
            b: token1 {
              s: symbol
            }
            v: volumeUSD
            l: reserveUSD
          }
        }
      `,
      ).then(({data}) =>
        (data.dailyData as any[]).map(({a, b, v, l}: Record<string, number & {s: number}>) => ({
          token0: +a.s,
          token1: +b.s,
          volume: +v,
          liquidity: +l,
        })),
      );
    },
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    pairs: query.data || [],
  };
};

import styled from "styled-components";

import {br, flex, microPalette, palette, size, text, transition} from "../../styles";
import {Skeleton} from "../skeleton";

export const Wrapper = styled.div<{tokens?: boolean; arrow?: boolean; micro?: boolean; disabled?: boolean}>`
  ${transition.fast.prop("filter")}
  ${(_) => (_.micro ? br.m : br.xl)}
  ${(_) => (!_.tokens ? (_.micro ? size.padding(3, 4) : size.padding(6, 6, 4)) : size.padding(6, 4, 4, 6))}

  background: ${microPalette.c500};
  width: 100%;
  position: relative;
  overflow: hidden;
  filter: saturate(1);

  ${(_) =>
    _.arrow &&
    `
    cursor: pointer;
  `}

  ${(_) =>
    _.disabled &&
    `
    pointer-events: none;
    filter: saturate(0);
  `}

  ${Skeleton} {
    position: relative;
    z-index: 2;
  }

  & > * {
    z-index: 2;
    position: relative;
  }

  ${(_) =>
    _.micro &&
    `
    ${Title} {
      margin-bottom: 0;
    }

    display: flex;
    justify-content: space-between;
  `}
`;

export const Title = styled.div<{hasChildren?: boolean}>`
  ${text.bodyLarge}

  position: relative;
  color: ${microPalette.c200};
  text-shadow:
    0 1px 4px ${microPalette.c500.o(0.7)},
    0 0 6px ${microPalette.c500.o(0.5)};
  margin-bottom: ${(_) => size(_.hasChildren ? 2 : 10)};
  pointer-events: none;
`;

export const Value = styled.div`
  ${text.h2}

  position: relative;
  color: ${palette.white};
  pointer-events: none;
`;

export const ArrowWrapper = styled.div`
  ${flex("center")}

  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const Effect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1 !important;
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
  width: auto;
  min-height: 60px;
`;

export const TokenContent = styled.div`
  ${size.gap(4)}

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0 2px 4px ${microPalette.c500.o(0.6)});
`;

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

//import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';

const graphURL = `https://api.studio.thegraph.com/query/12332/koi-finance/version/latest`
const graphURL_prod = 'https://gateway-arbitrum.network.thegraph.com/api/8024fb9453cc2a9eedcf11effd683c5c/subgraphs/id/NfixEQJCanis8URLkkP78Y2aKFGy5WBZgg5B7uuQAJq'
const graphURL_goldsky = 'https://api.goldsky.com/api/public/project_clmtie4nnezuh2nw6hhjg6mo7/subgraphs/mute_switch/v0.0.7/gn'

const graphURL_v3 = 'https://api.studio.thegraph.com/query/12332/koi-finance-v3/version/latest'
const graphURL_v3_prod = 'https://gateway-arbitrum.network.thegraph.com/api/8024fb9453cc2a9eedcf11effd683c5c/subgraphs/id/3gLgwpvmNybVfKeVLKcFLnpLvbtiwTQ4rLceVP7gWcjT'
const graphURL_v3_goldsky = 'https://api.goldsky.com/api/public/project_clmtie4nnezuh2nw6hhjg6mo7/subgraphs/koi-finance-v3/v0.0.2/gn'

export function getGraphQLUrl(isV3 = false){
  return isV3 ? graphURL_v3_goldsky : graphURL_goldsky;
}

export function getClient(is_mainnet, secondary = false){
  if(is_mainnet){
    return new ApolloClient({
      link: new HttpLink({
        uri: secondary ? graphURL : graphURL
      }),
      cache: new InMemoryCache(),
    })
  } else {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/mattt21/muteswitch_006',
      }),
      cache: new InMemoryCache(),
    })
  }
}

export function getClientV3(is_mainnet, secondary = false){
  if(is_mainnet){
    return new ApolloClient({
      link: new HttpLink({
        uri: secondary ? graphURL_v3_goldsky : graphURL_v3_goldsky
      }),
      cache: new InMemoryCache(),
    })
  } else {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/mattt21/muteswitch_006',
      }),
      cache: new InMemoryCache(),
    })
  }
}

export function getBlockClient(is_mainnet){
  if(is_mainnet){
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://api.studio.thegraph.com/query/12332/blocks---zksync-era/version/latest'
      }),
      cache: new InMemoryCache(),
    })
  } else {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/mattt21/zksync_blocks_006',
      }),
      cache: new InMemoryCache(),
    })
  }
}
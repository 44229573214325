import {Dropdown} from "./Dropdown";
import BigNumber from "bignumber.js";
import {useAccount} from "wagmi";
import {useWalletHook} from "../../web3/walletHook";
import UnknownToken from "../../assets/images/icons/unknown_token.png";
import {Card, Empty, Flex} from "../../ui/components";
import {PositionV3} from "./positionV3";

enum Modal {
  Switch,
  Connect,
  Confirm,
  Submitted,
}

/*
type LiquidityPair = {
  value: string;
  assetAImage: string;
  assetBImage: string;
  assetA: any;
  assetB: any;
}

interface YourLiquidityWidgetProps {
  ownedPairs: LiquidityPair[];
  addLiquidity: (LiquidityPair) => any;
  removeLiquidity: (LiquidityPair) => any;
}
*/

export const YourLiquidityWidget = ({manageLiquidity, ownedPairs, isLoading}) => {
  const {isConnected} = useAccount();

  const {getTokenFromContract} = useWalletHook();

  const getAssetLogo = (id) => {
    let _asset = getTokenFromContract(id);
    if (_asset) return _asset.logo;

    return UnknownToken;
  };

  const getWidget = () => {
    if (isConnected == false) {
      return <></>;
    }

    if (isLoading) {
      return [...new Array(6)].map(() => <Card size="l" loading height={90} />);
    }

    return (
      <>
        {ownedPairs.map((item, index) =>
          item.v3 ? (
            <PositionV3 position={item} />
          ) : (
            <Dropdown
              onManageClick={() => manageLiquidity(item.assetA, item.assetB, item.stable ? 1 : 2)}
              icon0={getAssetLogo(item.assetA)}
              icon1={getAssetLogo(item.assetB)}
              token0={item.assetASymbol}
              token1={item.assetBSymbol}
              stable={item.stable}
              fee={item.pairFeePercent}
              reserveA={item.assetATotal}
              reserveB={item.assetBTotal}
              tvl={item.reserveUSD}
              apy={
                item.ampAPY && item.apy
                  ? item.apy + "-" + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2)
                  : item.apy
              }
            />
          ),
        )}
        {ownedPairs.length == 0 ? <Empty title="No liquidity pool positions" card /> : <></>}
      </>
    );
  };

  return (
    <Flex column gap={2}>
      {!isConnected && (
        <Empty
          title="Connect your wallet"
          subtitle="Connect your web3 wallet and check your active liquidity pool positions."
          card
          showWallet
        />
      )}

      {getWidget()}
    </Flex>
  );
};

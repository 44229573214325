import {memo, useCallback} from "react";
import {useHistory} from "react-router";
import styled from "styled-components";
import {useWalletSelector} from "../../state/hooks";
import {selectIsOnFarm} from "../../state/selectors";
import {transition} from "../../styles";
import {LavaChip, Message} from "../../ui/components";
import {br, size} from "../../ui/styles";
import {sentGaEvent} from "../../utils";

interface LPOnFarmBannerProps {
  hasLP?: boolean;
  tokenAddressA: string;
  tokenAddressB: string;
}

export const LPOnFarmBanner = memo(function LPOnFarmBannerComponent(props: LPOnFarmBannerProps) {
  const {tokenAddressA, tokenAddressB, hasLP} = props;

  const history = useHistory();
  const farm = useWalletSelector((state) => selectIsOnFarm(state, tokenAddressA, tokenAddressB));

  const clickable = hasLP || farm?.isUserFarm;

  const goToFarm = useCallback(() => {
    if (!farm || !clickable) return;
    const {id, v2} = farm;
    sentGaEvent("lp_banner_farm_clicked", [farm.token0?.symbol, farm.token1?.symbol].join());
    if (v2) history.push(`/farmv2/${id}`);
    else history.push(`/farm/${id}`);
  }, [farm, clickable]);

  const apy =
    ((farm?.apy ? farm?.apy : farm?.maxAPY) / 100) * (farm?.ampBoost ? farm?.ampBoost : 1) +
    +(farm?.lp_apy ? farm?.lp_apy : farm?.lpAPY);
  return (
    <Wrapper onClick={goToFarm} open={!!farm}>
      {farm && (
        <LavaChip
          palette="purple"
          arrow={clickable}
          heading="Earn boosted yields"
          value={`Up to ${apy.toFixed(2)}% APY`}
        >
          {!clickable && <Message>Add liquidity to this pool to get an extra boost on your yields.</Message>}
        </LavaChip>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div<{open: boolean}>`
  @media (min-width: 980px) or ((max-width: 800px) and (max-width: 550px)) {
    ${br.xl}
    ${transition.slow}

    padding-bottom: 0;
    margin-bottom: ${size(-3)};
    overflow: hidden;
    max-height: 0;

    ${(_) =>
      _.open &&
      `
      max-height: ${172 + size.raw(3)}px;
      padding-bottom: ${size(3)};
    `}
  }
`;

import {useQuery} from "@tanstack/react-query";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";

const txFragment = (swap?: boolean) => `
  t: transaction {
    t: timestamp
  }
  p: pair {
    x: token0 {
      s: symbol
    }
    y: token1 {
      s: symbol
    }
  }
  u: amountUSD
  ${
    swap
      ? `
    ai: amount0In
    ao: amount0Out
    bi: amount1In
    bo: amount1Out
  `
      : `
    a: amount0
    b: amount1
  `
  }
`;

const mapTxType = <T extends string>(type: T, list: any[]) => list.map(mapTx).map((_) => ({type, ..._}));

const mapTx = ({p: {x, y}, t, u, a, ai, ao, b, bi, bo}: any) => ({
  date: t.t * 1000,
  token0: x.s as string,
  token1: y.s as string,
  amountUsd: +u,
  amount0: +(a || ai || ao),
  amount1: +(b || bi || bo),
});

export const useGetTransactionsV2Data = () => {
  const query = useQuery({
    queryKey: ["transactions-v2"],
    queryFn: async () => {
      const url = getGraphQLUrl();
      return await fetchGraphQL(
        url,
        `
        query {
          transactions(first: 200, orderBy: timestamp, orderDirection: desc) {
            mints(orderBy: timestamp, orderDirection: desc) {
              ${txFragment()}
            }
            burns(orderBy: timestamp, orderDirection: desc) {
              ${txFragment()}
            }
            swaps(orderBy: timestamp, orderDirection: desc) {
              ${txFragment(true)}
            }
          }
        }
      `,
      ).then(({data}: {data: {transactions: Record<string, any[]>[]}}) =>
        data.transactions
          .map((t) => {
            const {mints, burns, swaps} = t;
            return [...mapTxType("mints", mints), ...mapTxType("burns", burns), ...mapTxType("swaps", swaps)];
          })
          .flat(),
      );
    },
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    pairs: query.data || [],
  };
};

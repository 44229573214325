import {useQuery} from "@tanstack/react-query";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import {BUNDLE_ID, FACTORY_ADDRESS} from "./constants";
import {getBlockByDate} from "./utils";

export type AnalyticsGlobalData = Exclude<ReturnType<typeof useGetGlobalData>["data"], undefined>;

export const useGetGlobalData = () => {
  const query = useQuery({
    queryKey: ["eth-price"],
    queryFn: async () => {
      const oneDayBefore = await getBlockByDate(60 * 60 * 24);
      const url = getGraphQLUrl();
      return await fetchGraphQL(
        url,
        `
        query {
          ec: bundles(where: {id: ${BUNDLE_ID}}) {
            ethPrice
          }
          ep: bundles(where: {id: ${BUNDLE_ID}}, block: {number: ${oneDayBefore}}) {
            ethPrice
          }
          fc: muteSwitchFactories(where: {id: "${FACTORY_ADDRESS}"}) {
            txCount
            pairCount
          }
          fp: muteSwitchFactories(where: {id: "${FACTORY_ADDRESS}"}, block: {number: ${oneDayBefore}}) {
            txCount
          }
        }
      `,
      ).then(({data}) => ({
        ethPrice: +data.ec[0].ethPrice,
        ethDayPrice: +data.ep[0].ethPrice,
        ethDayChange: +data.ec[0].ethPrice - +data.ep[0].ethPrice,

        pairCount: +data.fc[0].pairCount,

        txDayCount: +data.fc[0].txCount - +data.fp[0].txCount,

        blocks: {
          day: oneDayBefore,
        },
      }));
    },
    refetchOnWindowFocus: false,
    refetchInterval: 20_000,
  });

  return query;
};

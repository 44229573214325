import {CSSProperties} from "react";
import styled from "styled-components";

import {size, PaddingString} from "../../styles";
import {layoutMq} from "../box";

interface FlexProps {
  align?: CSSProperties["alignItems"];
  justify?: CSSProperties["justifyContent"];
  alignSelf?: CSSProperties["alignSelf"];
  justifySelf?: CSSProperties["justifySelf"];
  wrap?: CSSProperties["flexWrap"] | boolean;
  column?: boolean;
  gap?: number | string;
  expandVertical?: boolean;
  expandHorizontal?: boolean;
  noBasis?: boolean;
  grow?: boolean | number;
  order?: boolean | number;
  padding?: PaddingString;
}

const FlexComp = styled.div<FlexProps>`
  display: flex;

  ${(_) => (_.align || !_.column) && `align-items: ${_.align || "center"};`}
  ${(_) => _.justify && `justify-content: ${_.justify};`}
  ${(_) => _.alignSelf && `align-self: ${_.alignSelf};`}
  ${(_) => _.justifySelf && `justify-self: ${_.justifySelf};`}
  ${(_) => _.wrap && `flex-wrap: ${_.wrap === true ? "wrap" : _.wrap};`}
  ${(_) => _.grow && `flex-grow: ${typeof _.grow === "number" ? _.grow : 1};`}
  ${(_) => _.column && `flex-direction: column;`}
  ${(_) => _.gap && size.gap(+_.gap)}
  ${(_) => typeof _.order === "number" && `order: ${_.order}`}
  ${(_) => _.expandVertical && `height: 100%;`}
  ${(_) => _.expandHorizontal && `width: 100%;`}
  ${(_) => _.padding && size.padding(_.padding)}
  ${(_) =>
    _.noBasis &&
    `
    & > * {
      flex-basis: 0;
      flex-grow: 1;
    }
  `}
`;

const Grow = styled.div<{mobile?: boolean}>`
  flex-grow: 1;

  ${(_) =>
    _.mobile &&
    layoutMq.not(`
    flex-grow: initial;
  `)}
`;

const Break = styled.div`
  display: none;

  ${layoutMq(`
    display: block;
    min-width: 100%;
    max-width: 100%;
  `)}
`;

export const Flex = Object.assign(FlexComp, {
  Grow,
  Break,
});

import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import unknownToken from "../../assets/images/icons/unknown_token.svg";
import BigNumber from "bignumber.js";
import {formatNumber, abbrNum} from "../../utils/formatNumber";
import {Button, Card, Chip, Flex, IconAsset, layoutMq, Text, Tooltip, useLayoutMq} from "../../ui/components";

interface DropdownCommonProps {
  onManageClick: () => any;
  icon0: string;
  icon1: string;
  token0: string;
  token1: string;
  stable: boolean;
  fee: string;
  reserveA: string;
  reserveB: string;
  tvl?: string;
  apy?: string;
  dark?: boolean;
  loading?: boolean;
}

type DropdownProps = DropdownCommonProps | ({loading: true} & Partial<DropdownCommonProps>);

export const Dropdown = ({
  loading,
  onManageClick,
  icon0,
  icon1,
  token0,
  token1,
  stable,
  reserveA,
  reserveB,
  fee,
  apy,
  tvl,
  dark,
  children,
}: PropsWithChildren<DropdownProps>) => {
  const layoutBreak = useLayoutMq();
  if (loading) {
    return null;
  }
  return (
    <Card size="l" height={layoutBreak ? undefined : 90}>
      <Flex gap={2} justify="space-between" noBasis={!layoutBreak} expandVertical wrap={layoutBreak}>
        <Flex gap={4} grow={layoutBreak ? 2 : 16} order={0}>
          <Flex>
            <IconAsset stack asset={{logo: icon0 ? icon0 : unknownToken, symbol: token0} as any} size="s" button />
            <IconAsset stack asset={{logo: icon1 ? icon1 : unknownToken, symbol: token1} as any} size="s" button />
          </Flex>

          <div>
            <Text h6 noWrap>
              {token0}
              <Text inline color="gray300">
                {" ― "}
              </Text>
              {token1}
            </Text>
            <Text bodySmall color="gray400" noWrap>
              {stable ? "Stable Pool" : "Normal Pool"} {` - Fee ${fee}%`}
            </Text>
          </div>
        </Flex>

        <Break />

        <Flex gap={3} grow={layoutBreak ? 2 : 10} order={layoutBreak && 2}>
          <BreakPadding />
          <Flex column>
            <Text bodyRegular color="gray400">
              {token0}
            </Text>
            <Text bodyRegular color="gray400">
              {token1}
            </Text>
          </Flex>
          <Flex column>
            <Text bodyMedium>{abbrNum(reserveA, 7)}</Text>
            <Text bodyMedium>{abbrNum(reserveB, 7)}</Text>
          </Flex>
        </Flex>

        <Flex grow={layoutBreak ? 1 : 10} order={layoutBreak && 2}>
          {!layoutBreak ? (
            <div>
              {tvl && (
                <>
                  <Text bodyRegular color="gray400">
                    TVL
                  </Text>
                  <Text bodyMedium>{`$${formatNumber(new BigNumber(tvl).toFixed(0), 0)}`}</Text>
                </>
              )}
            </div>
          ) : (
            tvl && <Chip label="TVL" value={`$${formatNumber(new BigNumber(tvl).toFixed(0), 0)}`} />
          )}
        </Flex>

        <Flex order={layoutBreak && 1} column align="flex-start" gap={2} grow={layoutBreak ? 1 : 10}>
          {apy &&
            (!apy.includes("-") ? (
              <Chip valueColor label="APY" value={apy + "%"} />
            ) : (
              <>
                <Tooltip content="Deposit LP into Farms tab for boosted APY.">
                  <Chip palette="purple" label="Farm APY" value={apy?.split("-")[1] + "%"} variant="color" />
                </Tooltip>
                <Chip valueColor label="APY" value={apy?.split("-")[0] + "%"} />
              </>
            ))}
        </Flex>

        <Break />

        <Flex justify="flex-end" order={layoutBreak && 10} grow={layoutBreak ? 1 : 10}>
          <Button
            secondary
            full={layoutBreak}
            onClick={() => {
              onManageClick();
            }}
          >
            Manage
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

const Break = styled.div`
  display: none;

  ${layoutMq(`
    display: block;
    min-width: 100%;
    max-width: 100%;
    order: 2;
  `)}
`;

const BreakPadding = styled.div`
  min-width: 64px;
  max-width: 64px;
  display: none;

  ${layoutMq(`
    display: block;
  `)}
`;

import BigNumber from "bignumber.js";
import {ReactNode} from "react";
import styled from "styled-components";

const Num = styled.span`
  display: inline-block;
  white-space: nowrap;

  sub,
  sup {
    font-size: ${10 / 14}em;
    vertical-align: super;
  }
  sub {
    vertical-align: sub;
  }
`;

export function formatNumber(num: BigNumber.Value, digits: number) {
  const newNum = new BigNumber(num);

  if (newNum.lt(1)) {
    return newNum.toPrecision(3);
  }

  return newNum.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const suffixes = "KMBT";
export function abbrNum(n: BigNumber.Value, length = 4, maxDecimals = 2) {
  const pow = 10 ** length;
  n = Math.round(BigNumber(n).toNumber() * pow) / pow;

  let [{length: num}, {length: decimals}] = String(n).concat(".").split(".");
  const total = num + decimals;

  if (total <= length) {
    return numberWithCommas(+n.toFixed(maxDecimals));
  }
  if (num <= length) {
    return numberWithCommas(+n.toFixed(Math.min(maxDecimals, length - num)));
  }

  num = String(Math.round(n)).length;

  const step = ~~(Math.max(num - (length - 3), 0) / 3);
  const rest = 2 - (Math.max(num - length, 0) % 3);

  let result: any = +(Math.round(n * 10 ** (-step * 3 + rest)) / 10 ** rest).toFixed(rest);
  result = numberWithCommas(result);

  return result + (step ? suffixes[step - 1] : "");
}

export const getCleanNumber = (num: BigNumber.Value) => {
  if (new BigNumber(num).lte(1)) return new BigNumber(num).toPrecision(4);
  else return new BigNumber(num).toFixed(2);
};

export const numberWithCommas = (n: number | string = 0, decimals?: number) => {
  let [num, decimal = ""] = String(decimals === undefined ? n : Number(n).toFixed(decimals)).split(/\./);
  if (decimal) {
    decimal = "." + decimal;
  }
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimal;
};

export const formatPrice = (
  number: number | string = 0,
  dynamicDigits = false,
  digits = 5,
  minDecimals = 0,
): string => {
  try {
    let max = 2;
    let min = 0;
    if (dynamicDigits) {
      min = max = Math.max(0, digits - Math.floor(Math.log10(+number || 1)));
    }
    if (minDecimals) {
      max = Math.max(max, minDecimals);
      min = Math.max(min, minDecimals);
    }
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: max,
      minimumFractionDigits: min,
    })
      .format(+number)
      .replace(/^([0-9,]+\.(\d*?[1-9])*0+)$/, "$1")
      .replace(/(\.(0+)$)/, minDecimals ? "$1" : "")
      .replace(new RegExp(`(\\.[0-9]{0,${minDecimals}})0*$`), "$1");
  } catch {
    return "-";
  }
};

export const formatPriceInfinity = (value?: number, formatter = (n: number): ReactNode => formatPrice(n, true)) =>
  value === Infinity ? "∞" : value !== undefined ? formatter(value) : formatPrice(0, true, 2, 2);

export const formatPriceZeros = (value?: number | string | bigint, digits?: number, reduceFrom = 4) => {
  const n = formatPrice(Number(value), true, digits);
  const zeros = n.match(/\.(0+)/)?.[1]?.length || 0;
  if (zeros >= reduceFrom) {
    const [a, b] = n.split(/\.0+/);
    return (
      <Num title={n}>
        {a}.0
        <sub>{zeros}</sub>
        {b}
      </Num>
    );
  }
  return n;
};

export const formatNumberSmallDecimal = (
  num: Parameters<typeof formatNumber>[0],
  decimals: Parameters<typeof formatNumber>[1],
  keepDecimals?: boolean,
) => {
  const n = formatNumber(num, decimals);
  if (+n > 1) {
    return n;
  }
  let titleNum = String(+n);
  let [, baseNum, eNum]: any[] = n.match(/^([\.\d]+)e(-\d+)$/) || [];
  if (!eNum && Math.log10(+n) < -3) {
    eNum = Math.floor(Math.log10(+n));
    baseNum = n.replace(new RegExp(`0\.[0]{${Math.abs(eNum) - 1}}(\\d)`), "$1.");
  } else if (eNum) {
    titleNum = `0.${"0".repeat(Math.abs(eNum) - 1)}${baseNum.replace(".", "")}`;
  }
  if (keepDecimals) {
    return titleNum;
  }
  if (eNum) {
    return (
      <Num title={titleNum}>
        {baseNum}
        <sup>{eNum}</sup>
      </Num>
    );
  }
  return n;
};

export const extractNumber = (n: string) => {
  if (n.includes("∞")) return Infinity;
  return +n.replace(/^.*?([0-9\.,]+).*?$/, "$1").replace(/,/g, "");
};

import styled from "styled-components";

import {br, card, getColor, palette, PaletteColor, size} from "../../styles";

const sizes = {
  xxl: 72,
  xl: 48,
  l: 32,
  m: 24,
  s: 20,
  xs: 18,
  xxs: 14,
};
const buttonSizes: typeof sizes = {
  xxl: 80,
  xl: 60,
  l: 48,
  m: 40,
  s: 32,
  xs: 24,
  xxs: 20,
};
const buttonRadius: Record<keyof typeof sizes, keyof typeof br> = {
  xxl: "m",
  xl: "m",
  l: "m",
  m: "s",
  s: "xs",
  xs: "xs",
  xxs: "xs",
};

export interface Types {
  size?: keyof typeof sizes;
  rotate?: 1 | 2 | 3;
  contrast?: boolean;
  success?: boolean;
  error?: boolean;
  button?: boolean;
  color?: PaletteColor;
  background?: PaletteColor;
  variant?: string;
  stack?: boolean;
  withBorder?: boolean;
}

export const Wrapper = styled.div<Types>`
  ${br.xxs}

  display: inline-flex;
  overflow: hidden;
  color: ${(_) => _.color && getColor(_.color)};

  ${(_) =>
    _.success &&
    `
    background: ${palette.successLight};
    color: ${palette.success};
  `}

  ${(_) =>
    _.error &&
    `
    background: ${palette.errorLight};
    color: ${palette.error};
  `}

  ${(_) =>
    _.rotate &&
    `
    & > * {
      transform: rotate(${_.rotate * 90}deg);
    }
  `}

  ${(_) => {
    const isBoxed = _.button || _.success || _.error || _.contrast;
    const size = (isBoxed ? buttonSizes : sizes)[_.size || "m"] + +!!_.withBorder;
    return `
      min-width: ${size}px;
      max-width: ${size}px;
      min-height: ${size}px;
      max-height: ${size}px;
      padding: ${(size - sizes[_.size || "m"]) / 2}px;
    `;
  }}

  ${(_) =>
    _.button &&
    `
    ${(_.onClick ? card.button : card)(buttonRadius[_.size || "m"])}
    ${_.onClick ? `cursor: pointer;` : ""}
  `}

  ${(_) => {
    if (_.variant === "koi" && (_.button || _.contrast))
      return `
      ${card(buttonRadius[_.size || "m"], false, "transparent")}
      background: ${palette.ultramoss};
      color: ${palette.black};
    `;
  }}

  ${(_) =>
    (_.contrast || _.variant === "contrast") &&
    `
    background: ${palette.gray50};
  `}

  ${(_) =>
    _.stack &&
    `
    margin-left: ${size(-1)};

    &:first-child {
      margin-left: 0;
    }
  `}

  img {
    position: revert !important;
    border-radius: 4px;
    overflow: hidden;
  }

  svg {
    margin: auto;
    width: 100%;
    height: 100%;

    &:not([data-keep="true"]) {
      *[fill] {
        fill: currentColor !important;
      }
      *[stroke] {
        stroke: currentColor !important;
      }
    }
  }

  background: ${(_) => _.background && getColor(_.background)};
`;

import styled, {css, keyframes} from "styled-components";

import {br, microPalette, palette, transition} from "../../styles";

interface SkeletonProps {
  active?: boolean;
  width?: number | string;
  height?: number;
  variant?: "color" | "contrast";
  br?: keyof typeof br;
  hideChildren?: boolean;
}

const loadingPositionAnimation = keyframes`
  from {
    background-position-x: -400px;
  }
  to {
    background-position-x: calc(100% + 400px);
  }
`;

const loadingBackgroundAnimation = (contrast?: boolean) => keyframes`
  0%, 100% {
    background-color: ${contrast ? palette.gray100 : palette.white}
  }
  50% {
    background-color: ${contrast ? palette.gray200 : palette.gray50};
  }
`;

const loadingBackgroundColorAnimation = keyframes`
  0%, 100% {
    background-color: ${microPalette.c200.o(0.8)}
  }
  50% {
    background-color: ${microPalette.c300};
  }
`;

const toUnit = (n?: string | number) => (typeof n === "number" ? n + "px" : n ? n : "");

export const Skeleton = styled.div<SkeletonProps>`
  ${(_) => (_.br ? br[_.br] : br.xs)};

  ${(_) =>
    _.width &&
    _.active &&
    `
    min-width: ${toUnit(_.width)};
    max-width: ${toUnit(_.width)};
  `}
  ${(_) =>
    _.height &&
    _.active &&
    `
    min-height: ${toUnit(_.height)};
    max-height: ${toUnit(_.height)};
  `}

  ${(_) =>
    _.active &&
    css`
      overflow: hidden;
      white-space: nowrap;
      background-image: linear-gradient(
        90deg,
        transparent 0%,
        ${_.variant === "color" ? microPalette.c200 : _.variant === "contrast" ? palette.gray200 : palette.gray100} 50%,
        transparent 100%
      );
      background-size: 400px 100%;
      background-repeat: no-repeat;
      animation-name: ${loadingPositionAnimation},
        ${_.variant === "color"
          ? loadingBackgroundColorAnimation
          : loadingBackgroundAnimation(_.variant === "contrast")};
      animation-duration: ${_.variant === "color" ? "5s, 8s" : "5s, 2s"};
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      & > * {
        opacity: 0;
        pointer-events: none;
      }
    `}

  ${(_) =>
    _.hideChildren &&
    `
    ${transition.fast.prop("opacity")}
    ${
      _.active &&
      `
      & > * {
        opacity: 0;
      }
    `
    }
  `}
`;

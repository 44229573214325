import {Box} from "../../../../ui/components";

import {AnalyticsOverviewTokens} from "./overview-tokens";

import {AnalyticsChartVolume} from "./components/chart-volume";
import {AnalyticsChartLiquidity} from "./components/chart-liquidity";


interface AnalyticsOverviewProps {}

export const AnalyticsOverview = (props: AnalyticsOverviewProps) => {
  return (
    <>
      <Box noBreak vertical>
        <Box extendOnMobile>
          <Box column extendOnMobile>
            <AnalyticsChartLiquidity />
          </Box>
          <Box column extendOnMobile>
            <AnalyticsChartVolume />
          </Box>
        </Box>
        <AnalyticsOverviewTokens />
      </Box>
    </>
  );
};

import {cloneElement} from "react";
import {Asset} from "../../../model/Asset";
import {Asset as Web3Asset} from "../../../web3/types";

import unknownToken from "../../../assets/images/icons/unknown_token.svg";

import * as icons from "./icons";

import * as SC from "./index.styles";
import MUTE_TOKEN_LIST from "../../../web3/tokens/custom";
import COINGECKO_LIST from "../../../web3/tokens/coingecko";

export type Icons = keyof typeof icons;

type IconCommonProps = Omit<SC.Types, "variant"> & Omit<JSX.IntrinsicElements["div"], "ref" | "background">;
type IconProps = IconCommonProps & {
  icon?: keyof typeof icons;
  imageFallback?: string;
};

export const Icon = (props: IconProps) => {
  const {icon: iconName, imageFallback, ...rest} = props;
  const icon = icons[iconName || ""];
  let variant: string | undefined = undefined;
  if (iconName === "coinKoi") {
    variant = "koi";
  }
  if (iconName === "coinZk") {
    variant = "contrast";
  }
  return <SC.Wrapper {...{...rest, variant}}>{icon ? cloneElement(icon({})) : <img src={imageFallback} />}</SC.Wrapper>;
};

interface IconAssetPorps extends Omit<IconProps, "icon" | "imageFallback"> {
  asset?: Asset | Web3Asset;
  symbol?: string;
  address?: string;
}

export const IconAsset = ({asset, ...rest}: IconAssetPorps) => {
  const {symbol = rest?.symbol || "", logo} = asset || {};
  if (!symbol && !rest?.address) return <></>;
  const name = `coin${symbol?.charAt(0).toUpperCase() + symbol?.slice(1).toLowerCase()}`;
  const fallback = [MUTE_TOKEN_LIST, COINGECKO_LIST]
    .flat()
    .find((_) => _.symbol === symbol || _.address.toLowerCase() === rest?.address?.toLowerCase());
  return (
    <Icon {...rest} icon={(fallback?.coin || name) as any} imageFallback={logo || fallback?.logoURI || unknownToken} />
  );
};

import {memo, PropsWithChildren, ReactNode} from "react";

import * as SC from "./index.styles";

interface TooltipProps {
  content?: ReactNode;
  primary?: boolean;
  align?: AlignSetting;
}

export const Tooltip = memo(function TooltipComponent(
  props: PropsWithChildren<TooltipProps> & Pick<JSX.IntrinsicElements["div"], "style" | "className">,
) {
  const {content, primary, children, align, ...rest} = props;

  return (
    <SC.Wrapper {...rest}>
      {children}
      <SC.Tooltip align={align} primary={primary}>
        {content}
      </SC.Tooltip>
    </SC.Wrapper>
  );
});

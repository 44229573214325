import styled from "styled-components";

import {br, flex, microPalette, palette, size, text, transition} from "../../styles";

export const Wrapper = styled.div<{disabled?: boolean}>`
  ${transition.fast}
  ${size.padding(4, 4, 3)}
  ${size.gap(3)}
  ${br.m}
  ${flex("stretch", "center", "column")}

  background: ${palette.gray50};
  width: 100%;
  cursor: text;
  box-shadow: inset 0 0 0 1px transparent;

  &:hover,
  &:focus-within {
    box-shadow: inset 0 0 0 1px ${palette.gray200};
  }

  ${(_) =>
    _.disabled &&
    `
    pointer-events: none;
    opacity: 0.66;
  `}
`;

export const InputLineWrapper = styled.div`
  ${flex("center", "space-between")}
`;

export const Input = styled.input<{error?: boolean}>`
  ${transition.fast}
  ${text.h3}

  flex: 1;
  text-align: left;
  border: 0;
  width: 100%;
  background: transparent;
  color: ${palette.black};
  outline: none;
  margin-right: ${size(4)};
  -webkit-mask: linear-gradient(270deg, transparent 0, #fff ${size(2)}, #fff calc(100% - ${size(2)}));

  &::placeholder {
    color: ${palette.gray400};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  ${(_) =>
    _.error &&
    `
    color: ${palette.error};
  `}
`;

export const InputSubline = styled.div`
  ${flex("center", "space-between")}
  ${text.bodyRegular}

  color: ${palette.gray400};

  flex-wrap: wrap;
  gap: 8px;
`;

export const MaxAction = styled.span<{disabled?: boolean}>`
  ${transition.fast}

  margin-left: ${size(2)};
  color: ${microPalette.c400};
  cursor: pointer;

  &:hover {
    color: ${microPalette.c400.o(0.7)};
  }

  ${(_) =>
    _.disabled &&
    `
    color: ${palette.gray300};
  `}
`;

export const ErrorMessage = styled.span<{visible?: boolean}>`
  ${transition.fast}

  margin-left: ${size(2)};
  user-select: none;
  pointer-events: none;
  color: transparent;

  ${(_) =>
    _.visible &&
    `
    color: ${palette.error};
  `}
`;

import {memo, useCallback, ChangeEvent, useRef} from "react";
import {formatPrice} from "../../../utils";
import {Asset, LPAsset} from "../../../model/Asset";

import {AssetSelector} from "../index";
import BigNumber from "bignumber.js";

import * as SC from "./index.styles";

interface InputCoinProps {
  value?: number | string;
  setValue?: (value: string) => void;
  max?: number | string;
  min?: number | string;
  price?: number | string;
  balance?: number;
  disabled?: boolean;
  assets?: any;
  onAssetSelect?: (asset: Asset) => void;
  maxDisabled?: boolean;
  /** @deprecated */
  disableEdit?: boolean;
  fixedAsset?: boolean;
  disableInput?: boolean;
  selectedAsset?: Asset | LPAsset;
  noBalance?: boolean;
  /**
   * It replaces the price and balance row.
   */
  subtitle?: string;
}

export const InputCoin = memo(function InputCoinComponent(
  props: InputCoinProps & Omit<JSX.IntrinsicElements["input"], "ref">,
) {
  let {
    setValue,
    disabled,
    price,
    balance,
    assets,
    onAssetSelect,
    selectedAsset,
    disableEdit,
    fixedAsset = disableEdit,
    disableInput,
    noBalance,
    subtitle,
    ...rest
  } = props;

  const value = rest.value;

  if (selectedAsset?.balance && !balance) {
    balance = +selectedAsset?.balance;
  }

  if (selectedAsset?.price && !price) {
    price = +selectedAsset?.price;
  }

  const error = !noBalance && (!!balance || balance === 0) && +(value || 0) > +balance;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const v = event.target.value;

      if (v == "") {
        setValue?.("");
      }

      if (new BigNumber(v).toString() != "NaN") {
        if (v.length > 1 && !v.includes("0.") && !v.includes("0,"))
          setValue?.(new BigNumber(v?.replace(/^0+/, "")).toString());
        else setValue?.(v);
      } else if (v == ".") {
        setValue?.("0.");
      }
    },
    [setValue],
  );

  const setMax = useCallback(() => {
    setValue?.(new BigNumber(balance ? balance : 0).toFixed());
  }, [setValue, balance]);

  const focus = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const isInfinity = Number(rest.value) === Infinity;

  return (
    <SC.Wrapper onClick={focus} disabled={disabled}>
      <SC.InputLineWrapper>
        <SC.Input
          placeholder="0.00"
          {...rest}
          value={isInfinity ? "∞" : rest.value}
          type={isInfinity ? "text" : "number"}
          error={error && !isInfinity}
          ref={inputRef}
          onChange={handleChange}
          disabled={disableInput}
        />
        <AssetSelector asset={selectedAsset as any} assets={assets} setAsset={onAssetSelect} disabled={fixedAsset} />
      </SC.InputLineWrapper>
      <SC.InputSubline>
        {subtitle || (
          <>
            <div>
              {!price ? "—" : `~$${Number(+(price || 0) * +(value! || 0)).toFixed(2)}`}
              <SC.ErrorMessage visible={error}>Insufficient balance</SC.ErrorMessage>
            </div>
            <div>
              {!balance && balance !== 0 ? (
                "—"
              ) : (
                <>
                  Balance: {formatPrice(balance, true)}
                  {!noBalance && (
                    <SC.MaxAction onClick={setMax} disabled={disabled || balance === value}>
                      Max
                    </SC.MaxAction>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </SC.InputSubline>
    </SC.Wrapper>
  );
});

import React, {useState, useRef} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {AddLiquidity} from "./LiquidityPoolModals/AddLiquidity";
import {LiquidityRewardsWidget} from "./LiquidityRewardsWidget";
import {YourLiquidityWidget} from "./YourLiquidityWidget";
import {AllPoolsWidget} from "./AllPoolsWidget";
import {useWalletHook} from "../../web3/walletHook";
import {WalletState} from "../../state";
import {useWalletSelector} from "../../state/hooks";
import {Box, Tabs, Scroll, useLayoutMq, Title} from "../../ui/components";
import BigNumber from "bignumber.js";
import {Address, Asset} from "../../web3/types";
import {Pool, Position} from "@uniswap/v3-sdk";
import {Percent} from "@uniswap/sdk-core";

export const LiquidityPoolScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const layoutBreak = useLayoutMq();

  const addLiqModal = useRef<any>(null);

  const [section, setSection] = useState("a");

  const {createLiquidityPool, calculateLiquidityPool, getTokenFromContract} = useWalletHook();

  const tokens = useWalletSelector((state: WalletState) => state.tokens);
  const ownedPairs = useWalletSelector((state: WalletState) => state.ownedPairs);
  const ownedPairsV3 = useWalletSelector((state: WalletState) => state.ownedV3Liquidity);
  const allPairs = useWalletSelector((state: WalletState) => state.allPairs);
  const isLoading = useWalletSelector((state: WalletState) => state.ownedPairs == null);
  const isLoadingAll = useWalletSelector((state: WalletState) => state.allPairs == null || state.allPairs.length == 0);

  const addLiquidity = async (assetA, assetB, amountA, amountB) => {
    return await createLiquidityPool(assetA, assetB, amountA, amountB);
  };

  const calculateLiquidity = async (assetA, assetB, amountA, amountB) => {
    return await calculateLiquidityPool(assetA, assetB, amountA, amountB);
  };

  const manageLiquidity = (assetAAddress, assetBAddress, type) => {
    if (type == 0) {
      history.push(`/pool/v3/new/${assetAAddress}/${assetBAddress}`);
    } else {
      history.push(`/pool/${assetAAddress}/${assetBAddress}/${type == 1 ? "true" : "false"}`);
    }
  };

  const sortedLiq = React.useMemo(() => {
    var allVals: Record<Address, Asset> = {};

    for (let i in ownedPairs) {
      let _tokenA = getTokenFromContract(ownedPairs[i].assetA);
      let _tokenB = getTokenFromContract(ownedPairs[i].assetB);

      let assetAPrice = _tokenA && _tokenA.price ? _tokenA.price : "0";
      let assetBPrice = _tokenB && _tokenB.price ? _tokenB.price : "0";

      allVals["lp_" + String(i)] = {
        ...ownedPairs[i],
        assetAPrice,
        assetBPrice,
        hasLiq: true,
      };
    }

    for (let i in ownedPairsV3) {
      let _tokenA = ownedPairsV3[i].asset0;
      let _tokenB = ownedPairsV3[i].asset1;

      let _pool = ownedPairsV3[i].pool as Pool;
      if (_tokenA && _tokenA.price && _tokenB && _tokenB.price) {
        const position = new Position({
          pool: _pool,
          liquidity: Number(ownedPairsV3[i].liquidity.toString()),
          tickLower: Number(ownedPairsV3[i].tickLower.toString()),
          tickUpper: Number(ownedPairsV3[i].tickUpper.toString()),
        });

        const liqAmounts = position.burnAmountsWithSlippage(new Percent("0"));
        let totalValue = new BigNumber(0);
        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount0.toString())
            .div(Math.pow(10, _tokenA.decimals))
            .times(_tokenA.price ? _tokenA.price : 0),
        );

        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount1.toString())
            .div(Math.pow(10, _tokenB.decimals))
            .times(_tokenB.price ? _tokenB.price : 0),
        );

        allVals["lpv3_" + String(i)] = {
          ...ownedPairsV3[i],
          v3Price: totalValue.toFixed(),
          balance: totalValue.toFixed(),
          v3: true,
        };
      }
    }

    return Object.values(allVals)
      .filter((asset) => +asset.balance)
      .map((asset) => ({
        ...asset,
      }))
      .sort((a: any, b: any) => {
        let _aPrice = 0;
        let _bPrice = 0;
        if (a.hasLiq == true) {
          _aPrice = new BigNumber(a.assetAAmount)
            .times(a.assetAPrice)
            .plus(new BigNumber(a.assetBAmount).times(a.assetBPrice))
            .toNumber();
        } else if (a.v3) {
          _aPrice = new BigNumber(a.v3Price).toNumber();
        }

        if (b.hasLiq == true) {
          _bPrice = new BigNumber(b.assetAAmount)
            .times(b.assetAPrice)
            .plus(new BigNumber(b.assetBAmount).times(b.assetBPrice))
            .toNumber();
        } else if (b.v3) {
          _bPrice = new BigNumber(b.v3Price).toNumber();
        }

        return _bPrice - _aPrice;
      });
  }, [ownedPairs, ownedPairsV3]);

  return (
    <Box vertical>
      <div>
        <Title title="Liquidity Pools">
          {/*
            <AddLiquidityV3
            externalOpen={addLiqModal}
            assets={tokens}
            addLiquidity={addLiquidity}
            calculateLiquidity={calculateLiquidity}
            manageLiquidity={manageLiquidity}
          />

            */}

          <AddLiquidity
            externalOpen={addLiqModal}
            assets={tokens}
            addLiquidity={addLiquidity}
            calculateLiquidity={calculateLiquidity}
            manageLiquidity={manageLiquidity}
          />
        </Title>
        <Tabs
          onSelect={(val) => setSection(val)}
          standalone
          full
          tabs={[
            {value: "a", label: "Top Pools"},
            {value: "b", label: "My Liquidity"},
          ]}
        />
      </div>
      <Scroll padding="0 0 2" parentPadding="3 3" responsive height={90 * 6 + 12 * 5}>
        {section == "a" ? (
          <AllPoolsWidget ownedPairs={allPairs} manageLiquidity={manageLiquidity} isLoading={isLoadingAll} />
        ) : (
          <YourLiquidityWidget ownedPairs={sortedLiq} manageLiquidity={manageLiquidity} isLoading={isLoading} />
        )}
      </Scroll>

      <LiquidityRewardsWidget isVisible={false} onClose={() => {}} />
    </Box>
  );
};

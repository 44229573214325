import {useQuery} from "@tanstack/react-query";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";

export const useGetChartV3Data = () => {
  const query = useQuery({
    queryKey: ["charts-v3"],
    queryFn: async () => {
      const url = getGraphQLUrl(true);
      return await fetchGraphQL(
        url,
        `
        query {
          dailyData: koiFinanceDayDatas(first: 1000, orderBy: date, orderDirection: desc) {
            d: date
            v: volumeUSD
            l: tvlUSD
            # t: txCount
          }
        }
      `,
      ).then(({data}) =>
        (data.dailyData as any[]).map(({d, v, l, t}: Record<string, number>) => ({
          date: d * 1000,
          volume: +v,
          liquidity: +l,
          // txCount: +t,
        })),
      );
    },
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    chartData: query.data || [],
  };
};

import styled from "styled-components";

import {card, palette, size, text} from "../../styles";

const searchSvg = `
ICA8c3ZnIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d
3dy53My5vcmcvMjAwMC9zdmciPg0KICAgIDxwYXRoIGQ9Ik0xNi42MjY3IDE1LjUxMjlMMjAgMTguODg2MUwxOC44ODYxIDIwTDE1Lj
UxMjkgMTYuNjI2N0MxNC4zIDE3LjU5NzEgMTIuNzYxOCAxOC4xNzc2IDExLjA4ODggMTguMTc3NkM3LjE3NTc5IDE4LjE3NzYgNCAxN
S4wMDE4IDQgMTEuMDg4OEM0IDcuMTc1NzkgNy4xNzU3OSA0IDExLjA4ODggNEMxNS4wMDE4IDQgMTguMTc3NiA3LjE3NTc5IDE4LjE3
NzYgMTEuMDg4OEMxOC4xNzc2IDEyLjc2MTggMTcuNTk3MSAxNC4zIDE2LjYyNjcgMTUuNTEyOVpNMTUuMDQ2NSAxNC45Mjg0QzE2LjA
wOTYgMTMuOTM1OCAxNi42MDIzIDEyLjU4MTkgMTYuNjAyMyAxMS4wODg4QzE2LjYwMjMgOC4wNDI1OSAxNC4xMzUgNS41NzUyOSAxMS
4wODg4IDUuNTc1MjlDOC4wNDI1OSA1LjU3NTI5IDUuNTc1MjkgOC4wNDI1OSA1LjU3NTI5IDExLjA4ODhDNS41NzUyOSAxNC4xMzUgO
C4wNDI1OSAxNi42MDIzIDExLjA4ODggMTYuNjAyM0MxMi41ODE5IDE2LjYwMjMgMTMuOTM1OCAxNi4wMDk2IDE0LjkyODQgMTUuMDQ2
NUwxNS4wNDY1IDE0LjkyODRaIiBmaWxsPSIjODY4Njg2Ii8+DQogIDwvc3ZnPg==
`
  .replace("\n", "")
  .trim();

interface SearchProps {}

export const Search = styled.input<SearchProps>`
  ${card("xs")}
  ${size.padding(3)};
  ${text.bodyLarge}

  padding-left: ${size(11)};
  border: 0;
  width: 100%;
  height: 48px;
  background-image: url("data:image/svg+xml;base64,${searchSvg}");
  background-repeat: no-repeat;
  background-position: ${size(3)} ${size(3)};

  &:focus,
  &:hover {
    ${card("xs", false, palette.gray300, true)}
  }

  &::placeholder {
    color: ${palette.gray300};
  }
`;

import {CSSProperties} from "react";
import styled from "styled-components";

import {ellipsis, getColor, PaletteColor, text} from "../../styles";

type TextProps = {
  [type in keyof typeof text]?: boolean;
} & {
  align?: CSSProperties["textAlign"];
  inline?: boolean;
  color?: PaletteColor;
  noWrap?: boolean;
  ellipsis?: boolean;
};

export const Text = styled.div<TextProps>`
  ${(_) => _.ellipsis && ellipsis}

  ${(_) => {
    const type = Object.keys(_).find((type) => text[type] && _[type]);
    return type && text[type];
  }}

  ${(_) =>
    _.align &&
    `
    text-align: ${_.align};
  `}

  ${(_) =>
    _.inline &&
    `
    display: inline;
  `}

  ${(_) =>
    _.noWrap &&
    `
    white-space: nowrap;
  `}

  color: ${(_) => getColor(_.color)};
`;

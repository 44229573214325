import styled from "styled-components";

import {card, palette, size, text, transition, typo} from "../../styles";

export const Tooltip = styled.div<{primary?: boolean; align?: AlignSetting}>`
  ${size.padding(2, 3)}
  ${text.bodyRegular}
  ${(_) => _.primary && typo.primary.normal}
  ${card("xs", false, "transparent", false)}
  ${transition.normal}

  text-align: ${(_) => _.align || "left"};
  background: ${palette.black.o(0.6)};
  color: ${palette.white};
  position: absolute;
  max-width: 220px;
  width: max-content;
  top: 100%;
  margin-top: 0;
  left: 50%;
  transform-origin: 50% 0%;
  transform: translateX(-50%) scale(0.8);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  white-space: pre-line;
`;

export const Wrapper = styled.span`
  position: relative;
  display: inline-block;

  &:hover ${Tooltip} {
    transform: translateX(-50%) scale(1);
    opacity: 1;
    margin-top: ${size(1)};
  }
`;

import {memo, useCallback, useMemo} from "react";
import { useHistory} from "react-router-dom";
import { tickToPrice} from "@uniswap/v3-sdk";

import {Button, Card, Chip, Divider, Flex, IconAsset, PriceRange, Text, useLayoutMq} from "../../ui/components";
import { formatPriceInfinity, formatPriceZeros} from "../../utils";
import {PositionDataV3} from "../../model/WalletHookTypes";

import * as SC from "./positionV3.styles";

interface PositionV3Props {
  position: PositionDataV3;
}

export const PositionV3 = memo(function PositionV3Comp(props: PositionV3Props) {
  const {position} = props;
  const history = useHistory();
  const layoutBreak = useLayoutMq();
  const {pool} = position || ({} as never);

  const currentPrice = useMemo(() => {
    if (!pool) return;
    return +pool.token1Price.toSignificant();
  }, [pool?.token1Price]);

  const priceA = useMemo(() => {
    if (!pool) return;
    if (Number(position.tickUpper) > 887000) return Infinity;
    return +tickToPrice(pool.token1, pool.token0, Number(position.tickUpper)).toSignificant();
  }, [pool, position.tickUpper]);

  const priceB = useMemo(() => {
    if (!pool) return;
    if (Number(position.tickLower) < -887000) return 0;
    return +tickToPrice(pool.token1, pool.token0, Number(position.tickLower)).toSignificant();
  }, [pool, position.tickLower]);

  const {lowerPrice, upperPrice} = useMemo(() => {
    return {
      lowerPrice: Math.min(priceA!, priceB!),
      upperPrice: Math.max(priceA!, priceB!),
    };
  }, [priceA, priceB]);

  const goToPosition = useCallback(() => {
    history.push(`/pool/v3/${position.id}`);
  }, [position.id]);

  return (
    <Card size="l" height={layoutBreak ? undefined : 90}>
      <Flex gap={2} justify="space-between" expandVertical expandHorizontal wrap={layoutBreak}>
        <Flex gap={3}>
          <Flex>
            <IconAsset stack asset={position.asset0} size="s" button />
            <IconAsset stack asset={position.asset1} size="s" button />
          </Flex>
          <Flex>
            <div>
              <Text h6 noWrap>
                {position.asset0.symbol}
                <Text inline color="gray300">
                  {" ― "}
                </Text>
                {position.asset1.symbol}
              </Text>
              <Text bodySmall color="gray400" noWrap>
                NFT #{position.id} - Fee {position.fee / 10000}%
              </Text>
            </div>
            <Divider horizontal size={2} />
            <Chip value="V3" variant="color" />
          </Flex>
        </Flex>
        <SC.PriceRow>
          <SC.PriceContainer>
            <Text bodyRegular color="gray400">
              Current price:
            </Text>
            <Text bodyMedium inline>
              {formatPriceInfinity(currentPrice, (n) => formatPriceZeros(n, 5, 10))}
            </Text>
            <Divider size={1} horizontal />
            <Text bodyMicro inline color="gray400">
              {position.asset0.symbol} per {position.asset1.symbol}
            </Text>
          </SC.PriceContainer>
          <SC.PriceRange>
            <PriceRange rangeFrom={lowerPrice} rangeTo={upperPrice} price={currentPrice!} />
          </SC.PriceRange>
        </SC.PriceRow>
        <SC.Break />
        <Button secondary full={layoutBreak} onClick={goToPosition}>
          Manage
        </Button>
      </Flex>
    </Card>
  );
});
